<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
// import flatPickr from "vue-flatpickr-component";
// import "flatpickr/dist/flatpickr.css";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      page: 1,
      perPage: 5,
      pages: [],
      slice: {
        name: "",
        rate: "",
        month: null,
        day: null,
      },
      submited: false,
      spanView: false,
      slices: {},
      data: {},
      Months: [
        { value: 1, label: "Janvier" },
        { value: 2, label: "Février" },
        { value: 3, label: "Mars" },
        { value: 4, label: "Avril" },
        { value: 5, label: "Mai" },
        { value: 6, label: "Juin" },
        { value: 7, label: "Juillet" },
        { value: 8, label: "Août" },
        { value: 9, label: "Septembre" },
        { value: 10, label: "Octobre" },
        { value: 11, label: "Novembre" },
        { value: 12, label: "Décembre" },
      ],
      Days: [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" },
        { value: "11", label: "11" },
        { value: "12", label: "12" },
        { value: "13", label: "13" },
        { value: "14", label: "14" },
        { value: "15", label: "15" },
        { value: "16", label: "16" },
        { value: "17", label: "17" },
        { value: "18", label: "18" },
        { value: "19", label: "19" },
        { value: "20", label: "20" },
        { value: "21", label: "21" },
        { value: "22", label: "22" },
        { value: "23", label: "23" },
        { value: "24", label: "24" },
        { value: "25", label: "25" },
        { value: "26", label: "26" },
        { value: "27", label: "27" },
        { value: "28", label: "28" },
        { value: "29", label: "29" },
        { value: "30", label: "30" },
        { value: "31", label: "31" },
      ],
      title: "Gestion ecolages",
      items: [
        {
          text: "Ecolages",
          href: "/",
        },
        {
          text: "Gerer",
          active: true,
        },
      ],
    };
  },

  validations: {
    data: {
      classe_id: {
        required: helpers.withMessage("Veuillez choisir la classe", required),
      },
    },
  },
  components: {
    Layout,
    PageHeader,
    // flatPickr,
    Multiselect,
  },
  mounted() {
    this.getSlices();
  },

  methods: {
    initSliceCreation() {
      this.submited = true;
      this.createSlice();
    },

    createSlice() {
      this.spanView = true;
      const self = this;

      this.v$.data.$touch();

      const day = parseInt(this.data.day);
      const month = parseInt(this.data.month);

      function isValidDate(day, month) {
        const maxDaysPerMonth = [
          31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31,
        ];

        if (month < 1 || month > 12) return false;

        if (day < 1 || day > maxDaysPerMonth[month - 1]) return false;

        return true;
      }

      if (!isValidDate(day, month)) {
        this.spanView = false;
        Swal.fire({
          title: "Date invalide",
          text: "Le jour ou le mois que vous avez entré n'est pas valide.",
          icon: "error",
          confirmButtonClass: "btn btn-primary w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
        });
        return;
      }

      this.$store
        .dispatch("postRequest", { route: "api/slice", data: this.data })
        .then(
          function (response) {
            self.data = {};
            self.getSlices();
            self.spanView = false;
            Swal.fire(
              response.data.message,
              "Tranche créée avec succès!",
              "success"
            );
          },
          function (error) {
            if (error !== null) {
              self.spanView = false;
              Swal.fire({
                title: "Oops...",
                text: "La somme des pourcentages des tranches ne doit pas excéder 100%",
                icon: "error",
                confirmButtonClass: "btn btn-primary w-xs mt-2",
                buttonsStyling: false,
                showCloseButton: true,
              });
            }
          }
        );
    },

    initSliceUpdate() {
      this.submited = true;
      this.updateSlice();
    },

    updateSlice() {
      this.spanView = true;
      const self = this;
      this.$store
        .dispatch("putRequest", {
          route: `api/slice/${this.slice.id}`,
          data: this.slice,
        })
        .then(
          function (response) {
            self.spanView = false;
            self.getSlices();
            Swal.fire(
              response.data.message,
              "Modification éffectuer avec succès!",
              "success"
            );
          },
          function (error) {
            if (error !== null) {
              self.spanView = false;
              Swal.fire({
                title: "Oops...",
                text: "Il y a un problème!",
                icon: "error",
                confirmButtonClass: "btn btn-primary w-xs mt-2",
                buttonsStyling: false,
                showCloseButton: true,
              });
            }
          }
        );
    },

    getSlices() {
      const self = this;
      this.data.classe_id = this.$route.params.classeId;
      this.$store
        .dispatch("getRequest", {
          route: `api/sliceListing/${this.data.classe_id}`,
          data: this.data,
        })
        .then(
          function (response) {
            self.slices = response.data;
            self.setPages();
          },
          function (error) {
            console.log(error);
          }
        );
    },

    async deleteSlice(id) {
      const self = this;
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "voulez-Vous supprimer cette tranche? Cette action est irréversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-primary w-xs mt-2",
        cancelButtonClass: "btn btn-danger w-xs mt-2",
        confirmButtonText: "supprimer!",
      }).then(async (result) => {
        if (result.value == 1) {
          this.$store
            .dispatch("deleteRequest", {
              route: `api/slice/${id}`,
              data: this.data,
            })
            .then(
              function (response) {
                self.getSlices();
                console.log(response);
                Swal.fire({
                  title: "Supprimer",
                  text: "Faculté supprimée avec succès!",
                  icon: "success",
                });
              },
              function (error) {
                Swal.fire({
                  title: "Oops...",
                  text: error,
                  icon: "error",
                  confirmButtonClass: "btn btn-primary w-xs mt-2",
                  buttonsStyling: false,
                  showCloseButton: true,
                });
              }
            );
        }
      });
    },

    deleteFaculty(id) {
      const self = this;
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "voulez-Vous supprimer cette alerte? Cette action est irréversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-primary w-xs mt-2",
        cancelButtonClass: "btn btn-danger w-xs mt-2",
        confirmButtonText: "supprimer!",
      }).then(async (result) => {
        if (result.value) {
          this.$store
            .dispatch("postRequest", {
              route: "api/departments ",
              data: { idenfiant: id },
            })
            .then(
              function (response) {
                self.fileres = response.data.filieres;
                Swal.fire({
                  title: "Supprimer",
                  text: "Faculté supprimée avec succès!",
                  icon: "success",
                });
              },
              function (error) {
                Swal.fire({
                  title: "Oops...",
                  text: error,
                  icon: "error",
                  confirmButtonClass: "btn btn-primary w-xs mt-2",
                  buttonsStyling: false,
                  showCloseButton: true,
                });
              }
            );
        }
      });
    },
    getMonthLabel(value) {
      const months = [
        { value: "1", label: "Janvier" },
        { value: "2", label: "Février" },
        { value: "3", label: "Mars" },
        { value: "4", label: "Avril" },
        { value: "5", label: "Mai" },
        { value: "6", label: "Juin" },
        { value: "7", label: "Juillet" },
        { value: "8", label: "Août" },
        { value: "9", label: "Septembre" },
        { value: "10", label: "Octobre" },
        { value: "11", label: "Novembre" },
        { value: "12", label: "Décembre" },
      ];

      const month = months.find((m) => m.value === value);
      return month ? month.label : "";
    },
    getSlice(tranche) {
      this.slice = {
        name: tranche.name,
        rate: tranche.rate,
        month: tranche.month,
        day: tranche.day,
      };
    },
    setPages() {
      let numberOfPages = Math.ceil(this.fileres.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return data.slice(from, to);
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="col-md-12">
      <div class="card">
        <div class="card-body p-4">
          <div class="row justify-content-between mb-3">
            <div class="col-md-4 mb-3">
              <input type="text" class="form-control" placeholder="Search..." />
            </div>
            <div class="col-md-6 mb-3 d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-primary me-3"
                data-bs-toggle="modal"
                data-bs-target=".bs-example-modal-lg"
              >
                <i class="mdi mdi-plus"></i>
                Tranche
              </button>
            </div>
          </div>

          <div class="table-responsive table-card">
            <table class="table align-middle table-nowrap" id="customerTable">
              <thead class="table-light text-muted">
                <tr>
                  <th class="sort" scope="col" data-sort="currency_name">
                    Tranche
                  </th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Pourcentage
                  </th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Montant
                  </th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Date d'échéance
                  </th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Action
                  </th>
                </tr>
              </thead>
              <!--end thead-->
              <tbody class="list form-check-all">
                <tr v-for="(slice, index) in slices" :key="index">
                  <th scope="row">{{ slice.name }}</th>

                  <td>{{ slice.rate }} %</td>
                  <td>
                    {{
                      (parseInt(slice.rate) / 100) *
                      parseInt(slice.classe.ecolage)
                    }}
                    Fcfa
                  </td>
                  <td>{{ slice.day }} {{ getMonthLabel(slice.month) }}</td>

                  <td>
                    <div class="hstack gap-3 flex-wrap">
                      <a
                        data-bs-toggle="modal"
                        data-bs-target=".bs-faculty-update"
                        @click="getSlice(slice)"
                        class="link-success fs-15"
                        ><i class="ri-pencil-line"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        @click="deleteSlice(slice.id)"
                        class="link-danger fs-15"
                        ><i class="ri-delete-bin-line"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!--end tbody-->
            </table>
          </div>
          <div class="d-flex justify-content-end mt-3">
            <div class="pagination-wrap hstack gap-2">
              <a
                class="page-item pagination-prev disabled"
                href="#"
                v-if="page != 1"
                @click="page--"
              >
                Previous
              </a>
              <ul class="pagination listjs-pagination mb-0">
                <li
                  :class="{
                    active: pageNumber == page,
                    disabled: pageNumber == '...',
                  }"
                  v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
                  :key="index"
                  @click="page = pageNumber"
                >
                  <a class="page" href="#">{{ pageNumber }}</a>
                </li>
              </ul>
              <a
                class="page-item pagination-next"
                href="#"
                @click="page++"
                v-if="page < pages.length"
              >
                Next
              </a>
            </div>
          </div>
        </div>
        <div
          class="modal fade bs-example-modal-lg"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <form @submit.prevent="initSliceCreation">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="myLargeModalLabel">
                    Nouvelle Tranche
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="formationInput" class="form-label"
                          >Nom</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="annee"
                          placeholder="intitulé"
                          v-model="data.name"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="ecolage" class="form-label"
                          >Pourcentage</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="ecolage"
                          placeholder="Pourcentage"
                          v-model="data.rate"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="formationInput" class="form-label"
                          >Mois</label
                        >
                        <Multiselect
                          class="form-control"
                          v-model="data.month"
                          :close-on-select="true"
                          :searchable="true"
                          :show-labels="false"
                          placeholder="Jour"
                          :options="Months"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="formationInput" class="form-label"
                          >Jour</label
                        >
                        <Multiselect
                          class="form-control"
                          v-model="data.day"
                          :close-on-select="true"
                          :searchable="true"
                          :show-labels="false"
                          placeholder="Jour"
                          :options="Days"
                        />
                      </div>
                    </div>
                  </div>
                  <!--end row-->
                </div>
                <div class="modal-footer">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-link link-success fw-medium"
                    data-bs-dismiss="modal"
                  >
                    <i class="ri-close-line me-1 align-middle"></i> Close
                  </a>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="spanView"
                  >
                    <span
                      v-if="spanView"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    Ajouter
                  </button>
                </div>
              </div>
            </form>
            <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
        </div>
        <div
          class="modal fade bs-faculty-update"
          tabindex="-1"
          role="dialog"
          aria-labelledby="updateFacultyModal"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <form @submit.prevent="initSliceUpdate">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="updateFacultyModal">Modifier</h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="formationInput" class="form-label"
                          >Nom</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="annee"
                          placeholder="intitulé"
                          v-model="slice.name"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="ecolage" class="form-label"
                          >Pourcentage</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="ecolage"
                          placeholder="Pourcentage"
                          v-model="slice.rate"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="formationInput" class="form-label"
                          >Mois</label
                        >
                        <Multiselect
                          class="form-control"
                          v-model="slice.month"
                          :close-on-select="true"
                          :searchable="true"
                          :show-labels="false"
                          placeholder="Jour"
                          :options="Months"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="formationInput" class="form-label"
                          >Jour</label
                        >
                        <Multiselect
                          class="form-control"
                          v-model="slice.day"
                          :close-on-select="true"
                          :searchable="true"
                          :show-labels="false"
                          placeholder="Jour"
                          :options="Days"
                        />
                      </div>
                    </div>
                  </div>
                  <!--end row-->
                </div>
                <div class="modal-footer">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-link link-success fw-medium"
                    data-bs-dismiss="modal"
                  >
                    <i class="ri-close-line me-1 align-middle"></i> Close
                  </a>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="spanView"
                  >
                    <span
                      v-if="spanView"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    Confirmer
                  </button>
                </div>
              </div>
            </form>
            <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
        </div>
      </div>
    </div>
  </Layout>
</template>
